import { VALIDATION_MESSAGE } from "../../constants";
const Validation = {
  combine(required, validation) {
    return (value) => {
      let message = "";
      if (required) {
        message = this.required(value);
      }
      if (validation) {
        message = validation(value);
      }

      return message;
    };
  },
  noop() {
    return "";
  },
  required(value) {
    return !value ? VALIDATION_MESSAGE.REQUIRED : "";
  },
  requiredObjectAutocomplete(value) {
    return !(value && typeof value === "object")
      ? VALIDATION_MESSAGE.AUTOCOMPLETE_OBJECT
      : "";
  },
  email(value) {
    if (!/^\w+([-+.']\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/.test(value)) {
      return VALIDATION_MESSAGE.EMAIL_FORMAT_WRONG;
    }
    return "";
  },
  verifyPassword(value) {
    const { password, passwordVerification } = value;
    if (!!password) {
      if (password !== passwordVerification) {
        return VALIDATION_MESSAGE.PASSWORDS_DO_NOT_MATCH;
      }
      if (
        !/[A-Z]+/.test(password) ||
        !/[0-9]+/.test(password) ||
        password?.length < 8
      ) {
        return VALIDATION_MESSAGE.PASSWORD_CONSTRAINTS_ERROR;
      }
    }
    return "";
  },
  verifyDatetimeFormat(value) {
    if (value) {
      const d = new Date(value);
      return isNaN(d.valueOf()) ? VALIDATION_MESSAGE.DATETIME_FORMAT : "";
    } else {
      return VALIDATION_MESSAGE.REQUIRED;
    }
  },  
};

export default Validation;
