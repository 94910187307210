import React, { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";

import { StyledSwitch } from "../../components/Base";
import Button from "../../components/Button";
import {
  Form,
  Row,
  Input,
  Validation,
  Autocomplete,
} from "../../components/Form";
import "./index.scss";
import { getCrewById, updateCrew } from "../../api/crew";
import { getCountries } from "../../api/countries";

const validation = {
  companyName: Validation.required,
  firstName: Validation.required,
  lastName: Validation.required,
  email: Validation.required,
  phone: Validation.required,
  country: Validation.requiredObjectAutocomplete,
  address: Validation.required,
  postcode: Validation.required,
  city: Validation.required,
};

const CrewsEdit = ({ match, history }) => {
  const [countries, setCountries] = useState(null);
  const [status, setStatus] = useState("");

  const [defaultValues, setDefaultValues] = useState(null);
  useEffect(() => {
    (async () => {
      const newCountries = await getCountries();
      setCountries(newCountries);

      const crew = await getCrewById(match.params.crewId);
      const country = newCountries.find(
        (country) => country.code === crew.countryCode
      );
      setDefaultValues({
        companyName: crew.companyName,
        firstName: crew.firstName,
        lastName: crew.lastName,
        email: crew.email,
        phone: crew.phone,
        description: crew.description || "",
        country: country,
        address: crew.address,
        postcode: crew.postcode,
        city: crew.city,
      });
      setStatus(crew.status);
    })();
  }, [match.params.crewId]);

  async function editCrew(data) {
    const newCrew = {
      companyName: data.companyName,
      firstName: data.firstName,
      lastName: data.lastName,
      email: data.email,
      phone: data.phone,
      countryCode: data.country.code,
      description: data.description || null,
      status,
      address: data.address,
      postcode: data.postcode,
      city: data.city,
    };
    const crewId = match.params.crewId;
    const result = await updateCrew(crewId, newCrew);
    if (result.ok) {
      history.push("/crews");
    }
  }

  return (
    <>
      <h2>Edit Company</h2>
      <Paper
        variant="outlined"
        className="edit-crew-wrapper"
        style={{ padding: "1rem 1.5rem" }}
      >
        {countries && defaultValues ? (
          <Form
            onSubmit={editCrew}
            validation={validation}
            defaultValues={defaultValues}
          >
            <Row>
              <Input label="Company Name*" name="companyName" />
            </Row>
            <Row>
              <Input label="First Name*" name="firstName" />
            </Row>
            <Row>
              <Input label="Last Name*" name="lastName" />
            </Row>
            <Row>
              <Input label="Contact Email*" name="email" />
            </Row>
            <Row>
              <Input label="Contact Phone*" name="phone" />
            </Row>
            <Row>
              <Autocomplete
                items={countries}
                label="Select country"
                name="country"
              />
            </Row>
            <Row>
              <div className="change-status">
                <label>Status</label>
                <StyledSwitch
                  title="Change company status"
                  checked={status === "active"}
                  onChange={() =>
                    setStatus(status === "active" ? "passive" : "active")
                  }
                />
              </div>
            </Row>
            <Row>
              <Input label="Address*" name="address" />
            </Row>
            <Row>
              <Input label="Postcode*" name="postcode" />
            </Row>
            <Row>
              <Input label="City*" name="city" />
            </Row>
            <Row>
              <Input
                label="Description"
                name="description"
                additionalProps={{ multiline: true, rows: 3 }}
              />
            </Row>
            <Button type="submit" title="Save Company">Save</Button>
            <Button
              title="Go back"
              type="button"
              onClick={() => history.goBack()}
            >
              Cancel
            </Button>
          </Form>
        ) : null}
      </Paper>
    </>
  );
};
export default CrewsEdit;
