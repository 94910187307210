import React from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Button from "../../components/Button";

import {
  Form,
  DatePicker,
  Input,
  Password,
  Validation,
  Select,
  Row,
} from "../Form";
import { getCountriesList, getCountryByCode } from "../../services/countries";
import { LANGUAGES } from "../../constants";
import { StyledPaper } from "../Base";

const countries = getCountriesList();

const EditForm = ({ user, onSubmit }) => {
  return (
    <>
      <StyledPaper variant="outlined">
        <Form
          onSubmit={onSubmit}
          /* 
          validation={{
            newPassword: Validation.verifyPassword,
          }}
           */
          defaultValues={{
            fullname: user.fullname,
            email: user.email,
            birthday: moment(user.birthday),
            fcl: user.fcl,
            country: getCountryByCode(user.country),
            city: user.city,
            language: LANGUAGES.find(
              (language) => language.value === user.language
            ),
          }}
        >
          <Row>
            <Input label="Fullname" name="fullname" />
          </Row>
          <Row>
            <Input label="Email" name="email" disabled />
          </Row>
          {/* 
          <Row>
            <Password name="oldPassword" label="Old Password" />
          </Row>
          <Row>
            <Password
              name="newPassword"
              label="New Password"
              hasVerification={true}
              verificationLabel="Confirm New Password"
            />
          </Row>
         */}  
          <Row>
            <DatePicker
              name="birthday"
              disableFuture
              label="Birthday"
            />
          </Row>
          <Row>
            <Input label="Fcl" name="fcl" />
          </Row>
          <Row>
            <Select label="Country" name="country" itemList={countries} />
          </Row>
          <Row>
            <Input label="City" name="city" />
          </Row>
          <Row>
            <Select label="Language" name="language" itemList={LANGUAGES} />
          </Row>
          <Box>
            <Button type="submit" title="save changes">Save</Button>
          </Box>
        </Form>
      </StyledPaper>
    </>
  );
};
export default EditForm;
