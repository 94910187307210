import React, { useContext } from "react";
import MenuItem from "@mui/material/MenuItem";
import MaterialSelect from "@mui/material/Select";

import FormContext from "./FormContext";

const Select = ({ name, label, itemList }) => {
  const context = useContext(FormContext);
  const { value, error } = context.get(name);

  const change = (e) => {
    const value = e.target?.value;
    context.set(name, value);
  };

  return (
    <MaterialSelect
      variant="outlined"
      type="text"
      value={value}
      label={label}
      error={!!error}
      size="small"
      {...(error ? { helperText: error } : {})}
      onChange={change}
      fullWidth
    >
      {itemList.map((item, index) => (
        <MenuItem key={index} value={item}>
          {item.label}
        </MenuItem>
      ))}
    </MaterialSelect>
  );
};

export default Select;
