export const COURSE_STATUS = {
  OK: "ok",
  EXPIRED: "expired",
  BUFFER: "buffer",
  NEVER_EXPIRE: "never expires",
  MISSING: "missing",
};

export const APPROVE_STATUS = {
  EXPIRED: "rejected",
  BUFFER: "pending",
};

export const ROUTES = {
  member: {
    positions: "positions",
    operations: "operations",
    aircraft: "aircraft",
    specializations: "specializations",
    all: "all",
  },
};

export const VALIDATION_MESSAGE = {
  REQUIRED: "Value is required",
  PASSWORDS_DO_NOT_MATCH: "The password confirmation does not match",
  PASSWORD_CONSTRAINTS_ERROR:
    "Passwords must have at least 1 digit(s) and at least 1 upper case letter(s) and contain at least 8 symbols.",
  EMAIL_FORMAT_WRONG: "Please enter valid email address",
  AUTOCOMPLETE_OBJECT: "Please select value from list",
  EMAIL_EXISTS: "Email already exists, please contact support for more info",
};

export const LANGUAGES = [
  { label: "English", value: "en" },
  { label: "Deutsch", value: "de" },
  { label: "French", value: "fr" },
  { label: "Italian", value: "it" },
];

export const BUFFER_ZONES = [1, 2, 3];

export const DEFAULT_DATE_FORMAT = "DD.MM.YYYY";
