import React, { useState, useEffect,  useRef  } from "react";
import Paper from "@mui/material/Paper";
import Confirm from "../../components/Confirm";
import Button from "../../components/Button";

import "./index.scss";
import {  addSchedule, getEventScheduled } from "../../api/schedule";
import {
  Form,
  Row,
  Input,
  Validation,
  DateTimePickerCustom
} from "../../components/Form";




const validation = {
  location: Validation.required,
  scheduleTrainingStartDate: Validation.required,
  scheduleTrainingEndDate: Validation.required,
};

const ScheduleAdd = ({ match, history }) => {
  const [param, setParam] = useState({});
  const [refresh, setRefresh] = useState(1);
  const [isModalShow, setIsModalShow] = useState(false);

  async function addScheduleForm(data) {
    if (data.scheduleTrainingStartDate > data.scheduleTrainingEndDate) {
      setIsModalShow(true)
    } else {
      data.courseTrainingId = param.trainingId;
      data.eventType = param.eventType;



      //param.eventType
      if (param.userId ==='all') {
        const usersAbleToPractical = await getEventScheduled(param.eventType, param.trainingId);
        data.userIds = usersAbleToPractical
                            .filter(el => (!el.start_date && !el.end_date))
                            .map( i => i.user_id );
      } else {
        data.userIds = [param.userId];
      }

      const result = await addSchedule(data);
      if (result.ok) {
        //param.eventType
        history.push(`/trainings/schedule/${param.eventType}/${param.trainingId}`);  
      }

 
    }
  }

  useEffect(() => {
    (async () => {
      setParam(match.params);
    })();
  }, [refresh]);

  
  async function confirmModal() {
    setIsModalShow(false)
  }
  
  return (
    <>
      <h2>Add new slot</h2>
      <Paper
        variant="outlined"
        style={{ padding: "1rem 1.5rem" }}
      >
        <Form onSubmit={addScheduleForm} validation={validation}>
            <Row>
              <Input label="Location*" name="location" />
            </Row>  
            <Row>
              <DateTimePickerCustom label="Start date" name="scheduleTrainingStartDate" />
            </Row>
            <Row>
              <DateTimePickerCustom label="End date" name="scheduleTrainingEndDate" />
            </Row>
          <Button type="submit" title="Add">Add</Button>
          <Button
            title="Go back"
            type="button"
            onClick={() => history.goBack()}
          >
            Cancel
          </Button>
        </Form>
      </Paper>
      {isModalShow  ? (
        <Confirm title={"Check your date interval"} notification={true} onConfirm={confirmModal}>
          <p>The end date is grather then the start data</p>
        </Confirm>
      ) : null}
    </>
  );
};

export default ScheduleAdd;
