import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "../../components/Button";
import { Form, DatePicker, FileInput, Textarea, Validation, Input } from "../../components/Form";
import { StyledPaper } from "../../components/Base";
import { fireEvent } from "../../hooks/usePubsub";
import {  uploadBankFile } from "../../api/orders";




const validation = {
  bankFile: Validation.required,
};

const MAX_FILE_SIZE = 1024 * 1024 * 10; // 10 Mb

const UploadBankFile = ({ match, history }) => {

  const submit = async (values) => { 
    if (values.bankFile[0]?.size >= MAX_FILE_SIZE) {
      return {
        bankFile: "File is too large. Maximum acceptable size is 10Mb",
      };
    }
    const result = await uploadBankFile({
      files: values.bankFile,
    });

    if (result.status === "ok") {  
      history.goBack();
      fireEvent("pending-changed");
      return null;
    } else {
      console.log("error")
      return result?.reason;
    }
    /* 
    if (error) {
      return {
        bankFile: `Error uploading file: ${error}`,
      };
    }
    */
  };

  return (
    <StyledPaper variant="outlined">
      <Form onSubmit={submit} validation={validation}>
        <Box m={2}>
          <Typography variant="subtitle1">
             Upload bank file
          </Typography>
        </Box>
        <Box m={2}>
          <FileInput
            name="bankFile"
            accept="*,.xml"
            label="Bank File*"
          />
        </Box>
        <Box m={2}>
          <Button type="submit" title="Upload">
            Upload
          </Button>
          <Button title="Back" onClick={() => history.goBack()}>
              Cancel
          </Button>
        </Box>
      </Form>
    </StyledPaper>
  );
};
export default UploadBankFile;

