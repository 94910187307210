import request from "./request";

export const getCrewOperations = async (crewId) => {
  const json = await request(`/crew/${crewId}/operations`);
  return json;
};

export const getCrewPositions = async (crewId) => {
  const json = await request(`/crew/${crewId}/positions`);
  return json;
};

export const getCrewOverview = async (crewId) => {
  const json = await getCrewOperations(crewId);
  const users = await request(`/crew/${crewId}/users/overview`);
    
  return {
    positions: json,
    users,
  };
};



export const addCrew = async (crew) => {
  const response = await request(`/crew`, "POST", crew);
  return response;
};

export const getCrewById = async (crewId) => {
  const json = await request(`./crew/${crewId}`);

  return json;
};

export const updateCrew = async (crewId, newCrew) => {
  const response = await request(`/crew/${crewId}`, "PUT", newCrew);

  return response;
};


export const inscribeCrewToEfb = async (crewId, companyName) => {
  const response = await request(`/crew/${crewId}/efb`, "POST", {companyName});
  return response;
};

export const uninscribeCrewToEfb = async (crewId, companyName) => {
  const response = await request(`/crew/${crewId}/efb`, "DELETE", {companyName});
  return response;
};




export const deleteCrewById = async (crewId) => {
  const json = await request(`/crew/${crewId}`, "DELETE");

  return json;
};

export const getCrewCourses = async (crewId) => {
  const json = await request(`/crew/${crewId}/courses`);
  return json;
};

export const getCrewAircrafts = async (crewId) => {
  const json = await request(`/crew/${crewId}/aircraft`);
  return json;
};

export const getCrews = async () => {
  const json = await request(`/crew`);
  return json;
};

export const getCrewApps = async (crewId) => {
  const json = await request(`/crew/${crewId}/apps`);
  return json;
}

export const getMembersList = async (crewId) => {
  const json = await request(`/crew/${crewId}/users`);
  return json;
};

export const removeMemberFromCrew = async (crewId, userId) => {
  const json = await request.delete(`/crew/${crewId}/users/${userId}`);
  return json;
};

export const getCrewRelations = async (crewId) => {
  const json = await request(`/relation/${crewId}`);
  return json;
};

export const getAircraftSpecialization = async (crewId) => {
  const json = await request(`/relation/aircraft/${crewId}`);
  return json;
};

export const getUserRoles = async (crewId, userId) => {
  const json = await request(`/crew/${crewId}/user/${userId}/roles`);
  return json;
};

export const setUserRoles = async (crewId, userId, roles) => {
  const json = await request.post(
    `/crew/${crewId}/user/${userId}/roles`,
    roles
  );
  return json;
};
