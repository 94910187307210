import React, { useState, useEffect } from "react";

import UserRoleForm from "../../components/UserRoleForm";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row, ErrorMessage } from "../../components/Base";

import { getCrews, setUserRoles } from "../../api/crew";
import { getUserRoles } from "../../api/user";
import { getRoles, getRolesApps } from "../../api/roles";

import UserRolesEditService from "./service";

import "./index.scss";

const UserCrewRolesEdit = ({ match }) => {
  const { userId } = match.params;
  const { crewId } = match.params;

  const [userCrewRoles, setUserCrewRoles] = useState(null);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [availableRoles, setAvailableRoles] = useState([]);
  const [error, setError] = useState(null);
  const [refresh, setRefresh] = useState(0);

  useEffect(() => {
    (async () => {

      const roles = await getRoles();

      setAvailableRoles(UserRolesEditService.mapRoles(roles));

      const userRoles = await getUserRoles(userId);
      setUserCrewRoles(userRoles);

      const selectedRoles = userRoles.find((r) => r.id === parseInt(crewId))?.roles || [];
      setSelectedRoles(selectedRoles);
      
    })();
  }, [userId, refresh]);


  const save = async (selectedRoles) => {

    const response = await setUserRoles(crewId, userId, selectedRoles);
    setRefresh(refresh + 1);
    return response?.ok;
  };

  const rowFormatter = (innerEl) => (
    <Row display={"flex"} mb={0} justifyContent={"space-between"}>

      <Row width={"100%"}>{innerEl}</Row>
    </Row>
  );

  const cancel = () => {
    console.log("cancel");
  };

  return (
    <>

        <>
          <BasePaper>
            <UserRoleForm
              rowFormat={rowFormatter}
              selected={selectedRoles}
              available={availableRoles}
              onCancel={cancel}
              onSave={save}
            />
            <ErrorMessage error={error} />
          </BasePaper>
        </>

    </>
  );
};

export default UserCrewRolesEdit;
