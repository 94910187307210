import countries from "./countries.json";

export const getCountryByCode = (code) =>
  countries.find(
    (country) => country.code.toLowerCase() === (code || "").toLowerCase()
  );

/**
 * @returns {{label, code}[]}
 */
export const getCountriesList = () => {
  return countries;
};

/**
 * @param {string} label switzerland, ALBANIA, etc.
 * @returns {{label, code}}
 */
export const getCountryByName = (label) => {
  return countries.find(
    (country) => country.label.toLowerCase() === label.toLowerCase()
  );
};
