import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Input, Validation, DatePicker } from "../../components/Form";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row } from "../../components/Base";
import Button from "../../components/Button";
import { StyledSwitch } from "../../components/Base";
import {  getProcessStepById, createProcessStep, updateProcessStep  } from "../../api/process-step";
import processStepList from "../../services/typeProcessStep.json";
import { createAssociationPositionProcessStep, updateAssociationPositionProcessStep , getAssociationPositionProcessStepById} from "../../api/associate-operation-process-step";
import { getOperations } from "../../api/operations";
import { getProcessStep } from "../../api/process-step";



const EditProcessStep = ({ match, history }) => {
  const { associateOperationProcessStepId } = match.params;
  const isAdd = !associateOperationProcessStepId;
  const [loaded, setLoaded] = useState(false);
  const [Operation, setOperation] = useState('');
  const [selectedOperation, setSelectedOperation] = useState('');
  const [processStep, setProcessStep] = useState(null);
  const [selectedProcessStep, setSelectedProcessStep] = useState();
  const [selectedStatus, setStatus] = useState("active");
  
  useEffect(() => {
    (async () => {

      const operationRes = await getOperations();
      setOperation(operationRes);
      const processStepRes = await getProcessStep();
      setProcessStep(processStepRes);

      if (associateOperationProcessStepId) {
        const associationPositionProcessStep = await getAssociationPositionProcessStepById(associateOperationProcessStepId);
        const operationReturn = operationRes.find((c) => c.id === associationPositionProcessStep[0].operationId);
        setSelectedOperation(operationReturn);
        const processStepIds = associationPositionProcessStep[0].data.map( item => item.processStepId);
        const processStepReturn = processStepRes.filter((c) => processStepIds.includes(c.id));   
        setSelectedProcessStep(processStepReturn);
      }
      setLoaded(true);
    })();
  }, [associateOperationProcessStepId]);

  const goBack = () => history.goBack();

  const onSubmit = async (values) => {

    const operationId = selectedOperation.id
    const processStepIds = selectedProcessStep.map((c) => c.id);

    let result;
    if (isAdd) {
      result = await createAssociationPositionProcessStep(operationId, processStepIds);
    } else {
      result = await updateAssociationPositionProcessStep(operationId, processStepIds);
    }
    if (result.ok) {
      history.push("/associate-operation-process-step");
    } 
  };

  const title = isAdd ? "Add new Associaton" : "Edit associaton";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            onSubmit={onSubmit}
          >
            <Row>
              <Autocomplete
                  label="Operation"
                  items={Operation}
                  selected={selectedOperation}
                  onChange={(items) => {
                    setSelectedOperation(items);
                  }}
                  formatLabel={(item) => `(${item.id}) ${item.name}`}
                />
            </Row>
             <Row>
              <Autocomplete
                label="Process Step List"
                items={processStep}
                selected={selectedProcessStep}
                multiple
                onChange={(items) => {
                  setSelectedProcessStep(items);
                }}
                formatLabel={(item) => `(${item.id}) ${item.name}`}
              />
            </Row>
            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default EditProcessStep;
