import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Form, Input, Validation, DatePicker } from "../../components/Form";
import Autocomplete from "../../components/Form/StyledAutocomplete";
import { BasePaper, Row } from "../../components/Base";
import Button from "../../components/Button";
import { getpassCondition, createPassCondition, updatePassCondition } from "../../api/passConditions";
import useUser from "../../hooks/useUser";
import passConditionCourseTypesList from "../../services/typePassConditionCourseList.json";

import moduleTrainingOptions from "../../services/moduleTraining.json";
import roleTrainingList from "../../services/roleTrainingList.json";


const EditPassConditions = ({ match, history }) => {
  const user = useUser();
  const { passConditionId } = match.params;
  const isAdd = !passConditionId;
  const [passCondition, setpassCondition] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [selectedModuleTraining, setModuleTraining] = useState(moduleTrainingOptions[0]);
  const [selectedPassConditionCourseTypes, setPassConditionCourseTypes] = useState(passConditionCourseTypesList[0]);
  const [selectedRoleTraining, setRoleTraining] = useState(roleTrainingList[0]);

  useEffect(() => {
    (async () => {

      if (passConditionId) {
        const passConditionRes = await getpassCondition(passConditionId);
        setpassCondition(passConditionRes);

        const rolePassCondition = roleTrainingList.find((c) => c.code === passConditionRes.role);
        setRoleTraining(rolePassCondition);
        const modulePassCondition = moduleTrainingOptions.find((c) => c.code === passConditionRes.module);
        setModuleTraining(modulePassCondition);
        const itemPassCondition = passConditionCourseTypesList.find((c) => c.code === passConditionRes.type);
        setPassConditionCourseTypes(itemPassCondition)

      }
      setLoaded(true);
    })();
  }, [passConditionId]);

  const goBack = () => history.goBack();

  const onSubmit = async (values) => {
    values.module = selectedModuleTraining.code;
    values.role = selectedRoleTraining.code;
    values.type = selectedPassConditionCourseTypes.code;
    if (values.type ==  'yes-no') {
      values.value = null;
    }
    let result;
    if (isAdd) {
      result = await createPassCondition(values);
    } else {
      result = await updatePassCondition(passConditionId, values);
    }
    if (result.ok) {
      history.push("/pass-conditions");
    } 
  };

  const title = isAdd ? "Add new pass Condition" : "Edit pass Condition";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            defaultValues={{
              name: passCondition?.name,
              description: passCondition?.description,
              role: passCondition?.role,
              module: passCondition?.module,
              type: passCondition?.type,
              value: passCondition?.value
            }}
            validation={{
              name: Validation.required,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <Input name="name" label="Pass Condition name*"></Input>
            </Row>

            <Row>
              <Input name="description" label="Pass Condition description"></Input>
            </Row>

            <Row>
              <Autocomplete
                  label="Evaluator"
                  items={roleTrainingList}
                  selected={selectedRoleTraining} 
                  onChange={(items) => {
                    setRoleTraining(items);
                  }}
                  formatLabel={(item) => `${item.name}`}
                />
            </Row>


            <Row>
              <Autocomplete
                  label="Module Training"
                  items={moduleTrainingOptions}
                  selected={selectedModuleTraining} 
                  onChange={(items) => {
                    setModuleTraining(items);
                  }}
                  formatLabel={(item) => `${item.name}`}
                />
            </Row>

            <Row>
              <Autocomplete
                  label="Pass Condition type"
                  items={passConditionCourseTypesList}
                  selected={selectedPassConditionCourseTypes}
                  onChange={(items) => {
                    setPassConditionCourseTypes(items);
                  }}
                  formatLabel={(item) => `${item.name}`}
                />
            </Row>
            {selectedPassConditionCourseTypes.code != 'yes-no'  &&
            //selectedPassConditionCourseTypes
            <Row>
              <Input name="value" label="Pass Condition value"></Input>
            </Row>
             }

            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default EditPassConditions;
