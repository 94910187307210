import React from "react";

import Input from "./Input";

const Textarea = ({ name, label, rows = 2 }) => (
  <Input
    name={name}
    label={label}
    additionalProps={{
      multiline: true,
      rows,
    }}
  />
);

export default Textarea;
