import request from "./request";

export const getAircraft = async () => {
  const json = await request(`/aircraft`);

  return json;
};

export const getAircraftById = async (aircraftId) => {
  const json = await request(`/aircraft/${aircraftId}`);

  return json;
};

export const addAircraft = async (aircraftName, groupAircraftId) => {
  const response = await request(`/aircraft`, "POST", {
    name: aircraftName,
    groupAircraftId:groupAircraftId
  });

  return response;
};

export const updateAircraft = async (aircraftId, newAircraft) => {
  const response = await request(`/aircraft/${aircraftId}`, "PUT", newAircraft);

  return response;
};

export const deleteAircraftById = async (aircraftId) => {
  const json = await request(`/aircraft/${aircraftId}`, "DELETE");

  return json;
};

export const getAircraftWithSpecialization = async (crewId) => {
  const json = await request(`/crew/${crewId}/relation/aircraft`);
  return json;
};

export const addCrewAircraft = async (crewId, aircraft) => {
  const json = await request.post(
    `/crew/${crewId}/relation/aircraft`,
    aircraft
  );
  return json;
};

export const deleteCrewAircraft = async (crewId, aircraftId) => {
  const json = await request(`/crew/${crewId}/${aircraftId}/aircraft/`, "DELETE");
  return json;
};


export const addCrewAircraftSpecializations = async (
  crewId,
  aircraftId,
  specializations
) => {
  const json = await request.post(
    `/crew/${crewId}/relation/aircraft/${aircraftId}`,
    specializations
  );
  return json;
};
