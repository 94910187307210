import React, { useContext } from "react";

import TextField from "@mui/material/TextField";

import FormContext from "./FormContext";

const Input = ({ name, label, additionalProps, disabled = false }) => {
  const context = useContext(FormContext);
  const { value, error } = context.get(name);

  const change = (e) => {
    const value = e.target?.value;
    context.set(name, value);
  };

  return (
    <TextField
      {...additionalProps}
      variant="outlined"
      // focused={!!value}
      type="text"
      value={value}
      label={label}
      error={!!error}
      disabled={disabled}
      size="small"
      {...(error ? { helperText: error } : {})}
      onChange={change}
      fullWidth
    />
  );
};

export default Input;
