import request from "./request";

export const getCustomPositionValidPeriods = async (courseId) => {
  const json = await request(
    `/position_valid_periods/custom_courses/${courseId}`
  );

  return json;
};

export const getMoodlePositionValidPeriods = async (courseId) => {
  const json = await request(
    `/position_valid_periods/moodle_courses/${courseId}`
  );

  return json;
};
