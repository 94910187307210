import request from "./request";
import CourseService from "../services/course";

export const getAllCourses = async (crewId = null) => {
  let url = `/courses`;
  if (crewId) {
    url += `?crewId=${crewId}`;
  }
  const json = await request(url);

  const hidden = CourseService.hide(json);
  return hidden;
};

export const getMoodleCourses = async () => {
  const json = await request(`/courses/moodle`);
  return json;
};

export const getCustomCourses = async () => {
  const json = await request(`/courses/custom`);
  return json;
};

export const getCompanyCourses = async (crewId) => {  
  const json = await request(`/courses/custom/crew/${crewId}`);
  return json;
};

export const getCustomCourseById = async (courseId) => {
  const json = await request(`/courses/custom/${courseId}`);

  return json;
};

export const getMoodleCourseById = async (courseId) => {
  const json = await request(`./courses/moodle/${courseId}`);

  return json;
};

export const deleteCustomCourseById = async (courseId) => {
  const json = await request(`./courses/custom/${courseId}`, "DELETE");

  return json;
};

export const updateCustomCourse = async (courseId, newCourse) => {
  const crew = newCourse.crewId ? `/${newCourse.crewId}` : "";
  const response = await request(
    `/courses/custom/${courseId}${crew}`,
    "PUT",
    newCourse
  );

  return response;
};

export const updateMoodleCourse = async (courseId, newCourse) => {
  const response = await request(
    `/courses/moodle/${courseId}`,
    "PUT",
    newCourse
  );

  return response;
};

export const addCustomCourse = async (newCourse) => {
  const crew = newCourse.crewId ? `/${newCourse.crewId}` : "";
  const response = await request(`/courses/custom${crew}`, "POST", newCourse);
  return response;
};

export const getCourse = async (courseId, isCustom) => {
  let url = `/courses/${courseId}`;
  if (isCustom) {
    url += "?custom=true";
  }
  const json = await request(url);
  return json;
};

export const uploadCourseCertificate = async ({
  courseId,
  userId,
  custom,
  files,
  completed,
  validUntil,
  comment,
  crewId,
}) => {
  const formData = new FormData();
  for (const file of files) {
    formData.append("file", file);
  }
  completed && formData.append("completed", completed.format("YYYY-MM-DD"));
  validUntil && formData.append("validUntil", validUntil.format("YYYY-MM-DD"));
  comment && formData.append("comment", comment);
  crewId && formData.append("crewId", crewId);
  userId && formData.append("userId", userId);

  const status = await request(
    `/certificate/${courseId}?userId=${userId}&custom=${custom}`,
    "POST",
    formData
  );

  return status;
};

export const toggleCourseVisibility = async (crewId, course) => {
  const json = await request.post(`/courses/toggle/${crewId}`, {
    id: course.id,
    groupId: course.groupId,
    isCustom: course.isCustom,
    categoryId: course.categoryId,
    positionId: course.positionId,
    operationId: course.operationId,
    aircraftTypeId: course?.aircraftTypeId,
    specializationId: course?.specializationId,
  });

  return json;
};

export const getAvailableCourses = async (crewId) => {
  const json = await request(`/courses/available/${crewId}`);
  return json;
};

export const createCourseRelation = async (crewId, relation) => {
  const url = !crewId ? `/courses/relation` : `/courses/relation/${crewId}`;
  const json = await request.post(url, relation);
  return json;
};

export const deleteRelation = async (crewId, relation) => {
  const url = !crewId ? `/courses/relation` : `/courses/relation/${crewId}`;
  const id = relation.groupId || relation.id;
  const isGroup = !!relation.groupId;
  let query = `id=${id}`;
  if (isGroup) {
    query += `&isGroup=true`;
  } else if (relation.isCustom) {
    query += `&isCustom=true`;
  }
  if (relation.categoryId) query += `&categoryId=${relation.categoryId}`;
  if (relation.positionId) query += `&positionId=${relation.positionId}`;
  if (relation.operationId) query += `&operationId=${relation.operationId}`;
  if (relation.aircraftTypeId)
    query += `&aircraftTypeId=${relation.aircraftTypeId}`;
  if (relation.specializationId)
    query += `&specializationId=${relation.specializationId}`;

  const json = await request.delete(`${url}?${query}`);
  return json;
};

export const getBundles = async (params = {}) => {
  const query = [];
  if (params.activeOnly) {
    query.push(`enabled=true`);
  }
  const bundles = await request(`/bundles/?${query.join("&")}`);
  return bundles;
};





export const getBundle = async (bundleId) => {
  const bundle = await request(`/bundles/${bundleId}`);
  return bundle;
};

const bundle2Request = (bundle, courses, crews, countries) => ({
  displayname: bundle.name,
  description: bundle.description,
  price: bundle.price,
  validPeriod: bundle.validPeriod,
  courses,
  crews,
  countries,
});




export const createBundle = async (bundle, courses, crews, countries) => {
  const response = await request.post(
    `/bundles`,
    bundle2Request(bundle, courses, crews, countries)
  );
  return response;
};

export const updateBundle = async (
  bundleId,
  bundle,
  courses,
  crews,
  countries
) => {
  const response = await request.put(
    `/bundles/${bundleId}`,
    bundle2Request(bundle, courses, crews, countries)
  );
  return response;
};

export const changeBundleStatus = async (bundleId, status) => {
  const response = await request.put(`/bundles/status/${bundleId}`, {
    active: status ? 1 : 0,
  });

  return response;
};


 
export const changeBundleRefresher = async (bundleId, isRefresher) => {
  const response = await request.put(`/training/refresh/${bundleId}`, {
    isRefresher: isRefresher ? 1 : 0,
  });
  return response;
};


export const getMoodleActiveCourses = async () => {
  const response = await request("/courses/active");
  return response;
};


export const getMoodleCoursesForTraining = async () => {
  const response = await request("/courses/training");
  return response;
};

