import React, { useState, useEffect } from "react";

import Typography from "@mui/material/Typography";

import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { Form, Input, Validation } from "../../../components/Form";
import Autocomplete from "../../../components/Form/StyledAutocomplete";

import { BasePaper, Row } from "../../../components/Base";
import Button from "../../../components/Button";

import {
  getMoodleActiveCourses,
  createBundle,
  getBundle,
  updateBundle,
} from "../../../api/courses";

import { getCountries } from "../../../api/countries";

import { getCrews } from "../../../api/crew";

import useUser from "../../../hooks/useUser";

const BundleEditForm = ({ match, history }) => {
  const user = useUser();
  const { bundleId } = match.params;
  const isAdd = !bundleId;
  const [bundle, setBundle] = useState(null);
  const [courses, setCourses] = useState([]);
  const [loaded, setLoaded] = useState(false);
  const [selectedCourses, setSelectedCourses] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);
  const [selectedCrews, setSelectedCrews] = useState([]);
  const [countries, setCountries] = useState([]);
  const [crews, setCrews] = useState(user.crews.map((crew) => {}));

  useEffect(() => {
    (async () => {
      const countries = await getCountries();
      setCountries(countries);

      const crews = await getCrews();
      setCrews(crews);

      if (bundleId) {
        const bundleRes = await getBundle(bundleId);
        setBundle(bundleRes);
        setSelectedCourses(bundleRes.courses || []);
        setSelectedCountries(
          bundleRes.countries?.map((countryId) =>
            countries.find((c) => c.id === countryId)
          ) || []
        );
        setSelectedCrews(
          bundleRes.crews?.map((crewId) =>
            crews.find((c) => c.id === crewId)
          ) || []
        );
      }

      const availableCourses = await getMoodleActiveCourses();
      setCourses(availableCourses);

      setLoaded(true);
    })();
  }, [bundleId]);

  const goBack = () => history.goBack();

  const onSubmit = async (values) => {
    const courses = selectedCourses.map((c) => c.id);
    const crews = selectedCrews.map((c) => c.id);
    const countries = selectedCountries.map((c) => c.id);
    let result;
    if (isAdd) {
      result = await createBundle(values, courses, crews, countries);
    } else {
      result = await updateBundle(bundleId, values, courses, crews, countries);
    }
    if (result.ok) {
      history.push("/bundles");
    }
  };

  const title = isAdd ? "Add new bundle" : "Edit bundle";

  return (
    <>
      <Typography variant="h4">
        <Button icon={<ArrowBackIcon />} onClick={goBack} />
        {title}
      </Typography>
      {loaded ? (
        <BasePaper>
          <Form
            defaultValues={{
              name: bundle?.displayname,
              description: bundle?.description,
              countries: bundle?.countries,
              price: bundle?.price,
              validPeriod: bundle?.validPeriod || 12,
              crew: bundle?.crewId,
            }}
            validation={{
              name: Validation.required,
            }}
            onSubmit={onSubmit}
          >
            <Row>
              <Input name="name" label="Bundle name*"></Input>
            </Row>
            <Row>
              <Input name="description" label="Bundle description"></Input>
            </Row>
            <Row>
              <Input name="price" label="Bundle price"></Input>
            </Row>
            <Row>
              <Input
                name="validPeriod"
                label="Default Subscription Valid Period In Months"
              ></Input>
            </Row>
            <Row>
              <Autocomplete
                label="Included courses"
                items={courses}
                selected={selectedCourses}
                multiple
                onChange={(items) => {
                  setSelectedCourses(items);
                }}
                formatLabel={(item) => `(${item.id}) ${item.displayname}`}
              />
            </Row>
            <Row>
              <Autocomplete
                name="countries"
                label="Limit to countries"
                items={countries}
                multiple
                onChange={(items) => {
                  setSelectedCountries(items);
                }}
                selected={selectedCountries}
                formatLabel={(item) => item.name}
              />
            </Row>
            <Row>
              <Autocomplete
                multiple
                name="crew"
                label="Limit to crews"
                items={crews}
                selected={selectedCrews}
                onChange={(items) => {
                  setSelectedCrews(items);
                }}
                formatLabel={(item) => item.companyName}
              />
            </Row>
            <Row>
              <Button type="submit">Save</Button>
              <Button onClick={goBack}>Cancel</Button>
            </Row>
          </Form>
        </BasePaper>
      ) : null}
    </>
  );
};

export default BundleEditForm;
