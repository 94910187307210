import React, { useState, useEffect } from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import Button from "../../components/Button";

import { Form, DatePicker, Input, Select, Row } from "../Form";
import { getCountriesList, getCountryByCode } from "../../services/countries";
import { StyledPaper } from "../Base";

import useCrew from "../../hooks/useCrew";
import { LANGUAGES } from "../../constants";

const countries = getCountriesList();

const EditMemberForm = ({ user, onSubmit }) => {
  const [selectEnable, setEnable] = useState("no");
  const [refresh, setRefresh] = useState(1);
  const { crew } = useCrew();
  const superadmin = crew.id === 0;

  return (
    <>
      <StyledPaper variant="outlined">
        {user ? (
          <>
            <Form
              onSubmit={onSubmit}
              validation={{}}
              defaultValues={{
                fullname: user.fullname,
                email: user.email,
                birthday: moment(user.birthday),
                country: getCountryByCode(user.country),
                city: user.city,
                language: LANGUAGES.find(
                  (language) => language.value === user.language
                )
              }}
            >
              <Row>
                <Input label="Fullname" name="fullname" />
              </Row>
              <Row>
                <Input label="Email" name="email" disabled={!superadmin} />
              </Row>
              {/* <Row>
                <Input name="password" label="Password" />
                <Button nosubmit onClick={genPassword}>Autogenerate</Button>
              </Row> */}
              <Row>
                <DatePicker
                  name="birthday"
                  disableFuture
                  label="Birthday"
                />
              </Row>
              <Row>
                <Select label="Country" name="country" itemList={countries} />
              </Row>
              <Row>
                <Input label="City" name="city" />
              </Row>
              <Row>
                <Select label="Language" name="language" itemList={LANGUAGES} />
              </Row>
              <Box>
                <Button type="submit" title="Save changes">Save</Button>
              </Box>

            </Form>
          </>
        ) : null}
      </StyledPaper>
    </>
  );
};
export default EditMemberForm;
