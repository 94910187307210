import { useState, useEffect, useMemo } from "react";

import { 
  Autocomplete,
  Box,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField} from '@mui/material';
import ClearIcon from "@mui/icons-material/Clear";

import Button from "../../components/Button";
import Row from "../../components/Form/Row";
import { StyledSwitch } from "../../components/Base";
import "./index.scss";

import { getValidPeriods } from "../../api/validPeriods";
import { getPositionsActive } from "../../api/positions";
import {
  getMoodleCourseById,
  getMoodleCourses,
  updateMoodleCourse,
} from "../../api/courses";
import IdGenerator from "../../utils/IdGenerator";
import { BUFFER_ZONES } from "../../constants";
import { getMoodlePositionValidPeriods } from "../../api/positionValidPeriods";
import { formatBufferZone, formatValidPeriod } from "../../services/formatters";

const VALID_PERIOD_ERROR_TEXT = "Please select validity period";
const POSITION_VALID_PERIOD_ERROR_TEXT = "Please, select correct data";
const idGenerator = new IdGenerator();

const MoodleCourseEdit = ({ history, match }) => {
  const [{ courseId }] = useState(match.params);
  const [courseName, setCourseName] = useState("");

  const [coursePrice, setCoursePrice] = useState(0);
  const [isCoursePriceFree, setIsCoursePriceFree] = useState(false);

  const [validPeriods, setValidPeriods] = useState([]);

  const [validPeriodId, setValidPeriodId] = useState("");

  const [validPeriodErrorText, setValidPeriodErrorText] = useState(null);
  const [bufferZone, setBufferZone] = useState(BUFFER_ZONES[0]);

  const [refreshers, setRefreshers] = useState([]);
  const [selectedRefreshers, setSelectedRefreshers] = useState([]);

  const [positions, setPositions] = useState([]);
  const [positionValidPeriods, setPositionValidPeriods] = useState([]);

  const [isCrewTrainerSeen, setIsCrewTrainerSeen] = useState(true);
  const [isShowAtCourses, setIsShowAtCourses] = useState(false);

  const [summary, setSummary] = useState("");
  const [summary2, setSummary2] = useState("");

  const [woocommerceProducts, setWoocommerceProducts] = useState(null);
  const [matchedProduct, setMatchedProduct] = useState(null);

  function handleValidPeriod(value) {
    setValidPeriodId(value);
    if (!value) return setValidPeriodErrorText(VALID_PERIOD_ERROR_TEXT);
    setValidPeriodErrorText(null);
  }

  function addRefresherCourse(value) {
    // Do nothing if value is empty (user deleted text in Autocomplete)
    if (!value) return;

    // Prevent error when value in Autocomplete is event object
    let refresher = {};
    if (value.id) refresher = value;

    const refresherCourse = {
      id: idGenerator.getGeneratorId(),
      refresher: refresher,
    };
    setSelectedRefreshers((oldValue) => [...oldValue, refresherCourse]);
  }

  const simpleSelectedRefreshers = useMemo(() => {
    return selectedRefreshers.map((refresherWrap) => refresherWrap.refresher);
  }, [selectedRefreshers]);

  const filteredRefreshers = useMemo(() => {
    return refreshers.filter(
      (item) =>
        simpleSelectedRefreshers.findIndex(
          (selectedItem) => selectedItem && selectedItem.id === item.id
        ) === -1
    );
  }, [refreshers, simpleSelectedRefreshers]);

  function handleSelectRefresher(refresher, id) {
    const index = selectedRefreshers.findIndex((item) => item.id === id);
    setSelectedRefreshers((oldValue) => {
      const newSelectedRefreshers = [...oldValue];
      newSelectedRefreshers[index].refresher = refresher;
      return newSelectedRefreshers;
    });
  }

  function onRefresherStatusChange(id, newActive) {
    const index = selectedRefreshers.findIndex((item) => item.id === id);
    setSelectedRefreshers((oldValue) => {
      const newSelectedRefreshers = [...oldValue];
      newSelectedRefreshers[index].refresher.isActive = newActive;
      return newSelectedRefreshers;
    });
  }

  function deleteRefresher(id) {
    const index = selectedRefreshers.findIndex((item) => item.id === id);
    setSelectedRefreshers((oldValue) => {
      const newSelectedRefreshers = [...oldValue];
      newSelectedRefreshers.splice(index, 1);
      return newSelectedRefreshers;
    });
  }

  function addPositionValidPeriod(value, validPeriodId = "") {
    let position = {};
    if (value.id) position = value;

    const positionValidPeriod = {
      id: idGenerator.getGeneratorId(),
      position: position,
      validPeriodId: validPeriodId,
      isValid: null,
    };
    setPositionValidPeriods((oldValue) => [...oldValue, positionValidPeriod]);
  }

  function toggleCoursePriceFree(isCoursePriceFree) {
    setIsCoursePriceFree(isCoursePriceFree);
    if (isCoursePriceFree) {
      setCoursePrice(0);
    }
  }

  const simplePositions = useMemo(() => {
    return positionValidPeriods.map(
      (positionValidPeriodWrap) => positionValidPeriodWrap.position
    );
  }, [positionValidPeriods]);

  const filteredPositions = useMemo(() => {
    return positions.filter(
      (item) =>
        simplePositions.findIndex((selectedItem) => {
          if (selectedItem) return selectedItem.id === item.id;
          return false;
        }) === -1
    );
  }, [positions, simplePositions]);
  function handleSelectPosition(value, id) {
    // Handle removing text in autocomplete
    let position = value;
    if (!value) position = {};
    const index = positionValidPeriods.findIndex((item) => item.id === id);
    setPositionValidPeriods((oldValue) => {
      const newPositionValidPeriods = [...oldValue];
      newPositionValidPeriods[index].position = position;
      return newPositionValidPeriods;
    });
  }
  function handleSelectPositionPeriod(validPeriodId, id) {
    const index = positionValidPeriods.findIndex((item) => item.id === id);
    setPositionValidPeriods((oldValue) => {
      const newPositionValidPeriods = [...oldValue];
      newPositionValidPeriods[index].validPeriodId = validPeriodId;
      return newPositionValidPeriods;
    });
  }
  function deletePositionValidPeriod(id) {
    const index = positionValidPeriods.findIndex((item) => item.id === id);
    setPositionValidPeriods((oldValue) => {
      const newPositionValidPeriods = [...oldValue];
      newPositionValidPeriods.splice(index, 1);
      return newPositionValidPeriods;
    });
  }
  async function editCourse() {
    if (!validPeriodId) return handleValidPeriod(validPeriodId);
    let isPositionPeriodsValid = true;

    if (positionValidPeriods.length) {
      positionValidPeriods.forEach((positionValidPeriod, index) => {
        if (
          Object.keys(positionValidPeriod.position).length === 0 ||
          positionValidPeriod.validPeriodId === ""
        ) {
          isPositionPeriodsValid = false;
          setPositionValidPeriods((oldValue) => {
            const newPositionValidPeriods = [...oldValue];
            newPositionValidPeriods[index].isValid = false;
            return newPositionValidPeriods;
          });
        } else {
          setPositionValidPeriods((oldValue) => {
            const newPositionValidPeriods = [...oldValue];
            newPositionValidPeriods[index].isValid = true;
            return newPositionValidPeriods;
          });
        }
      });
    }

    if (!isPositionPeriodsValid) return;
    const newPositionValidPeriods = positionValidPeriods.map(
      (positionValidPeriod) => ({
        positionId: positionValidPeriod.position.id,
        validPeriodId: positionValidPeriod.validPeriodId,
      })
    );
    const moodleCourse = {
      price: isCoursePriceFree ? null : coursePrice,
      validPeriodId: validPeriodId ? validPeriodId : null,
      bufferZone: isValidPeriodExpire ? bufferZone : null,
      showAtAllCourses: Number(isShowAtCourses),
      summary: summary ? summary : null,
      summary2: summary2 ? summary2 : null,
      refreshers: simpleSelectedRefreshers,
      positionValidPeriods: newPositionValidPeriods,
      woocommerceProductId: matchedProduct?.id,
    };
    const result = await updateMoodleCourse(courseId, moodleCourse);

    if (result.ok) {
      history.push("/courses/moodle");
    }
  }

  const isValidPeriodExpire = useMemo(() => {
    return validPeriodId > 1;
  }, [validPeriodId]);

  useEffect(() => {
    (async () => {
      const moodleCourse = await getMoodleCourseById(courseId);
      const positionValidPeriods = await getMoodlePositionValidPeriods(
        courseId
      );
      // clear selected valid periods before loading (because of possible re-render)
      setPositionValidPeriods([]);
      positionValidPeriods.forEach((positionValidPeriod) => {
        addPositionValidPeriod(
          positionValidPeriod.position,
          positionValidPeriod.validPeriod.id
        );
      });

      const newPositions = await getPositionsActive();
      setPositions(newPositions);
      if (moodleCourse.price === null) {
        setCoursePrice(0);
        setIsCoursePriceFree(true);
      } else {
        setCoursePrice(+moodleCourse.price);
        setIsCoursePriceFree(false);
      }
      const newValidPeriods = await getValidPeriods();
      setValidPeriods(newValidPeriods);
      setCourseName(moodleCourse.displayName);
      setBufferZone(
        moodleCourse.buffer ? moodleCourse.buffer : BUFFER_ZONES[2]
      );
      if (moodleCourse.validPeriodId) {
        setValidPeriodId(moodleCourse.validPeriodId);
      }
      setIsShowAtCourses(!!moodleCourse.showAtAllCourses);
      setSummary(moodleCourse.summary ? moodleCourse.summary : "");
      setSummary2(moodleCourse.summary2 ? moodleCourse.summary2 : "");

      // TODO: not all courses but refreshers
      const newRefreshers = await getMoodleCourses();
      setRefreshers(newRefreshers);

      // clear selected refreshers before loading (because of possible re-render)
      setSelectedRefreshers([]);
      if (moodleCourse.refreshers) {
        moodleCourse.refreshers.forEach((refresher) =>
          addRefresherCourse(refresher)
        );
      }
      /* 
      const woocommerceProducts = await getWoocommerceProducts();
      setWoocommerceProducts(woocommerceProducts);
      const wpr = moodleCourse.woocommerceProductId;
      if (wpr) {
        setMatchedProduct(woocommerceProducts.find((p) => p.id === wpr));
      }
      */
    })();
  }, [courseId]);

  return (
    <>
      <h2>Edit Moodle Course</h2>

      <Paper
        variant="outlined"
        className="edit-moodle-course"
        style={{ padding: "1rem 1.5rem" }}
      >
        {courseName ? (
          <>
            <TextField
              variant="outlined"
              size="small"
              label="Course ID"
              value={courseId}
              className="id-field"
              disabled
            />
            <TextField
              variant="outlined"
              size="small"
              label="Course Full Name*"
              className="name-field"
              value={courseName}
              disabled
            />
            <div className="price-field">
              <TextField
                variant="outlined"
                size="small"
                className="price-input"
                label="Course price"
                value={coursePrice}
                onChange={(event) => {
                  setCoursePrice(+event.target.value);
                }}
                disabled={isCoursePriceFree}
              />
              <label>Free</label>
              <StyledSwitch
                title="Change course price free"
                checked={isCoursePriceFree}
                onChange={() => toggleCoursePriceFree(!isCoursePriceFree)}
              />
            </div>
            <FormControl
              variant="outlined"
              size="small"
              error={!!validPeriodErrorText}
              className="select-course-period"
            >
              <InputLabel id="selectCoursePeriod">
                Select default validity period*
              </InputLabel>
              <Select
                labelId="selectCoursePeriod"
                label="Select default validity period*"
                value={validPeriodId}
                onChange={(event) => handleValidPeriod(event.target.value)}
              >
                {validPeriods.map((period) => (
                  <MenuItem key={period.id} value={period.id}>
                    {formatValidPeriod(period.validPeriod)}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{validPeriodErrorText}</FormHelperText>
            </FormControl>
            {isValidPeriodExpire ? (
              <FormControl
                variant="outlined"
                size="small"
                className="select-buffer"
              >
                <InputLabel id="selectBufferZone">
                  Select Buffer Zone
                </InputLabel>
                <Select
                  className="select-buffer-zone"
                  labelId="selectBufferZone"
                  label="Select Buffer Zone"
                  value={bufferZone}
                  onChange={(event) => {
                    setBufferZone(event.target.value);
                  }}
                >
                  {BUFFER_ZONES.map((buffer) => (
                    <MenuItem key={buffer} value={buffer}>
                      {formatBufferZone(buffer)}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : null}
            {woocommerceProducts ? (
              <Autocomplete
                className="id-field"
                size="small"
                options={woocommerceProducts}
                getOptionLabel={(product) => `${product?.name}`}
                value={matchedProduct}
                onChange={(_, newProduct) => setMatchedProduct(newProduct)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    label="WooCommerce Matching Product"
                    size="small"
                  />
                )}
              />
            ) : null}
            <div className="switch-change">
              <label>Company trainer seen</label>
              <StyledSwitch
                title="Change crew trainer seen status"
                checked={isCrewTrainerSeen}
                onChange={() => setIsCrewTrainerSeen(!isCrewTrainerSeen)}
              />
            </div>
            <div className="switch-change">
              <label>Show at all courses</label>
              <StyledSwitch
                title="Change at all courses showing status"
                checked={isShowAtCourses}
                onChange={() => setIsShowAtCourses(!isShowAtCourses)}
              />
            </div>
            <Row>
              <Button onClick={addPositionValidPeriod}>
                Add Position and validity period
              </Button>
            </Row>
            <Box>
              {positionValidPeriods.map((positionValidPeriod, index) => (
                <FormControl
                  key={positionValidPeriod.id}
                  error={positionValidPeriod.isValid === false}
                  className="position-period-row-wrapper"
                >
                  <div className="position-period-row">
                    <Autocomplete
                      className="select-position"
                      size="small"
                      options={filteredPositions}
                      getOptionLabel={(position) =>
                        position.name ? position.name : ""
                      }
                      filterSelectedOptions
                      value={positionValidPeriod.position}
                      onChange={(_, newPosition) =>
                        handleSelectPosition(
                          newPosition,
                          positionValidPeriod.id
                        )
                      }
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          variant="outlined"
                          label="Select position*"
                          size="small"
                        />
                      )}
                    />
                    <FormControl
                      className="select-period"
                      variant="outlined"
                      size="small"
                    >
                      <InputLabel
                        id={"selectPositionPeriod" + positionValidPeriod.id}
                      >
                        Select validity period
                      </InputLabel>
                      <Select
                        labelId={"selectCoursePeriod" + positionValidPeriod.id}
                        label="Select validity period"
                        value={positionValidPeriod.validPeriodId}
                        onChange={(event) => {
                          handleSelectPositionPeriod(
                            event.target.value,
                            positionValidPeriod.id
                          );
                        }}
                      >
                        {validPeriods.map((period) => (
                          <MenuItem key={period.id} value={period.id}>
                            {formatValidPeriod(period.validPeriod)}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <Button
                      title="Delete position and validity period"
                      icon={<ClearIcon />}
                      onClick={() =>
                        deletePositionValidPeriod(positionValidPeriod.id)
                      }
                    />
                  </div>
                  <FormHelperText>
                    {positionValidPeriod.isValid === false
                      ? POSITION_VALID_PERIOD_ERROR_TEXT
                      : null}
                  </FormHelperText>
                </FormControl>
              ))}
            </Box>

            <TextField
              className="summary"
              variant="outlined"
              multiline
              size="small"
              label="Summary"
              value={summary}
              rows="3"
              onChange={(event) => setSummary(event.target.value)}
            />

            <TextField
              className="summary2"
              variant="outlined"
              multiline
              size="small"
              label="Summary2"
              value={summary2}
              rows="3"
              onChange={(event) => setSummary2(event.target.value)}
            />
            <Row>
              <Button onClick={addRefresherCourse}>Add Refresher Course</Button>
            </Row>
            <Box>
              {selectedRefreshers.map((refresherWrap) => (
                <div key={refresherWrap.id} className="refresher-row">
                  <Autocomplete
                    className="select-refresher"
                    size="small"
                    options={filteredRefreshers}
                    getOptionLabel={(refresher) =>{
                      return refresher.shortName ? refresher.shortName + " ("+ refresher.id + ")" : ""
                    }
                    }
                    onChange={(_, newRefresher) =>
                      handleSelectRefresher(newRefresher, refresherWrap.id)
                    }
                    value={refresherWrap.refresher} 
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="outlined"
                        label={"Select refresher*"}
                        size="small"
                      />
                    )}
                  />
                  <label>Is Active</label>
                  <StyledSwitch
                    title="Change refresher status"
                    checked={!!refresherWrap.refresher?.isActive}
                    onChange={() =>
                      onRefresherStatusChange(
                        refresherWrap.id,
                        refresherWrap.refresher?.isActive ? 0 : 1
                      )
                    }
                  />
                  <Button
                    title="Delete refresher"
                    icon={<ClearIcon />}
                    onClick={() => deleteRefresher(refresherWrap.id)}
                  />
                </div>
              ))}
            </Box>
            <div>
              <Button title="Save moodle course" onClick={editCourse}>
                Save Moodle Course
              </Button>
              <Button title="Go back" onClick={() => history.goBack()}>
                Cancel
              </Button>
            </div>
          </>
        ) : null}
      </Paper>
    </>
  );
};

export default MoodleCourseEdit;
