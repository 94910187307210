import React, { useState, useEffect } from "react";
import TableComponent from "../../components/TableComponent";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { getNgftCrewApps, addNgftCrewApps, deleteNgftCrewApps } from "../../api/ngftApp.js";
import { getNgftApp } from "../../api/ngftApp.js";
import { getCrews } from "../../api/crew.js";
import { StyledSwitch } from "../../components/Base";



const CrewNgftApps = ({ match, history }) => {

  const [crewApp, setCrewApp] = useState([]);
  const [refresh, setRefresh] = useState(1);


  useEffect(() => {
    (async () => {
  
      const appsList = await getNgftApp();
      const crewList = await getCrews();
      const appsActiveList = await getNgftCrewApps();


      const allCrewApps = [];

      crewList.forEach(crew => appsList.forEach (app => {
        const find = appsActiveList.find(elem => elem.companyId === crew.id && elem.appId === app.id);
        let status = "passive";
        if (find !== undefined){
          status = "active";
        }
        const unique_id = (crew.id).toString().concat((app.id).toString());
        allCrewApps.push({"id": unique_id , "crewId":crew.id, "companyName":crew.companyName, "appId":app.id, "appName":app.name, "status":status});
      }));

      setCrewApp(allCrewApps);
    })();
  }, [refresh]);

  const onStatusChange = (crewId, appId, status) => {
    if (status === "passive"){
       // delete row 
       deleteNgftCrewApps(crewId, appId);
    } else {
      // insert rom
      addNgftCrewApps({"crewId":crewId, "appId":appId, "status":"active"});
    }
    setRefresh(refresh * -1);
  };
 
  return (
    <>
    <h2>NGFT APPs</h2>
    <TableComponent

      header={[

        { title: "Company Name" },
        { title: "NGFT APPs" },
        { title: "Enable" , style: { width: "5%" } },
      ]}
      renderChildren={(item) => (
        <TableRow key={item.id}>

          <TableCell>{item.companyName}</TableCell>
          <TableCell>{item.appName}</TableCell>
          <TableCell>
            <StyledSwitch
              title="Change active apps"
              checked={item.status === "active"}
              onChange={() =>
                onStatusChange(
                  item.crewId,
                  item.appId,
                  item.status === "active" ? "passive" : "active"
                )
              }
            />
          </TableCell>
      </TableRow>
      )}
      items={crewApp}
      search={["companyName"]}
      pagination
    />
  </>
  );
};

export default CrewNgftApps;
