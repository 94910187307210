import React from "react";

import Box from "@mui/material/Box";

import ExpandableSectionTitle from "../ExpandableSection/ExpandableSectionTitle";
import CertificateList from "./CertificateList";

const AircraftList = ({ aircraft }) => {
  return (
    <>
      {aircraft?.map((a) => (
        <Fragment key={a.id}>
          <Box
            display="flex"
            marginLeft="1rem"
            marginBottom="1rem"
            marginTop="2rem"
          >
            <ExpandableSectionTitle status={a.status} title={a.title} />
          </Box>
          <CertificateList certificates={a.certificates} header={true} />
        </Fragment>
      ))}
    </>
  );
};

export default AircraftList;
