import request from "./request";

export const getPositions = async () => {
  const json = await request(`/positions`);

  return json;
};

export const getPositionsActive = async () => {
  const json = await request(`/positions/active`);

  return json;
};

export const getPositionById = async (positionId) => {
  const json = await request(`/positions/${positionId}`);

  return json;
};

export const addPosition = async (positionName, categoryId) => {
  const response = await request(`/positions`, "POST", {
    name: positionName,
    categoryId: categoryId,
  });

  return response;
};

export const updatePosition = async (positionId, newPosition) => {
  const response = await request(
    `/positions/${positionId}`,
    "PUT",
    newPosition
  );

  return response;
};

export const deletePositionById = async (positionId) => {
  const json = await request(`/positions/${positionId}`, "DELETE");

  return json;
};
